import { SettingState } from "@/store/modules/setting/state";
import getters from "@/store/modules/setting/getters";
import mutations from "@/store/modules/setting/mutations";
import actions from "@/store/modules/setting/actions";
import { AttendanceType, FeedbackType, UnitType } from "@/schemas/Enums";

const state: SettingState = {
  setting: {
    carousel_tips: false,
    carousel_tip_unit: UnitType.DisplayTime,
    attendance_type: AttendanceType.Physical,
    single_session_time: 10,
    group_session_time: 20,
    text_only: true,
    feedback: true,
    tip_overview: true,
    display_username_copy : false,
    feedback_details: [
      {
        type: FeedbackType.Performance,
        display_feedback_message: true,
      },
      { type: FeedbackType.Participation, display_feedback_message: true },
    ],
    activity_id: "",
    // group_based_feedback: true,
  },
  settings: [],
};

const setting = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default setting;
