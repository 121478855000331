import { BaseRepository } from "@/repository/base";
import { ISetting } from "@/schemas/ISetting";
import { apiUrl } from "@/env";
import { authHeader, corsHeaders } from "@/repository/deps";

class SettingRepository extends BaseRepository<ISetting> {
  getSettingScores(token: string, settingId: string): Promise<any> {
    const resource = `${apiUrl}/${this.basePath}/${settingId}/scores`;
    return fetch(resource, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getSettingScoresWithName(token: string, settingId: string): Promise<any> {
    const resource = `${apiUrl}/${this.basePath}/${settingId}/scoresnamed`;
    return fetch(resource, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getSettingScoreMax(token: string, settingId: string): Promise<any> {
    const resource = `${apiUrl}/${this.basePath}/${settingId}/scoreMax`;
    return fetch(resource, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getSettingSessions(token: string, settingId: string): Promise<any> {
    const resource = `${apiUrl}/${this.basePath}/${settingId}/sessions`;
    return fetch(resource, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
}

const settingRepository = new SettingRepository("settings");

export default settingRepository;
