export enum Roles {
  User = "user",
  Admin = "admin",
  Researcher = "researcher",
  Instructor = "instructor",
  WaitingInstructor = "instrucWait",
}

export enum ActivityType {
  Ranking = "ranking",
  Assignment = "assignment",
}

export enum AttendanceType {
  Hybrid = "hybrid",
  Physical = "physical",
  Remote = "remote",
}

export enum Scope {
  Group = "group",
  Individual = "individual",
}

export enum MoveType {
  Moved = "moved",
  Added = "added",
  Removed = "removed",
}

export enum SessionState {
  Created = "created",
  RunningSingle = "running_single",
  RunningGroup = "running_group",
  Finished = "finished",
  Pending = "pending",
}

export enum UserActions {
  Agreed = "agreed",
  Denied = "denied",
  Request = "request",
  Ended = "ended",
}

export enum EventType {
  EnterFeedback = "enter_feedback",
  LeaveFeedback = "leave_feedback",
  MoveForward = "move_forward",
  MoveBackward = "move_backward",
  ExampleDialogOpen = "example_dialog_open",
  ExampleDialogClose = "example_dialog_close",
}

export enum FeedbackType {
  Participation = "participation",
  Performance = "performance",
  // GroupBased = "group_based",
}

export enum UnitType {
  Occurrences = "occurrences",
  DisplayTime = "display_time"
}

export enum disciplineType {
  economie_gestion = "economie_gestion",
  sti_genie_mecanique = "sti_genie_mecanique",
  genie_aeronautique = "genie_aeronautique",
  maths_physique_chimie = "maths_physique_chimie",
  biotechnologies_sante_environnement = "biotechnologies_sante_environnement",
  lettres_histoire_geographie = "lettres_histoire_geographie",
  metiers_de_la_mode = "metiers_de_la_mode",
  sciences_et_techniques_medico_sociales = "sciences_et_techniques_medico_sociales",
  genie_industriel_bois = "genie_industriel_bois",
  genie_civil = "genie_civil",
  hotellerie_restauration = "hotellerie_restauration",
  anglais_lettres = "anglais_lettres",
  melec = "melec",
  autre = "autre"
}