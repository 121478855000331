import {apiUrl, appBaseDomain} from "@/env";
import userRepository from "@/repository/user.ts";
import activityRepository from "@/repository/activity.ts";
import { corsHeaders, formDataHeaders, jsonHeaders } from "@/repository/deps";
import {ActionContext} from "vuex";

const repository = {
  user: userRepository,
  activity: activityRepository,
  /**
   * Authenticate user to the API
   * @param credentials
   */
  getToken: (credentials: {
    username: string;
    password: string;
  }): Promise<any> => {
    return fetch(`${apiUrl}/token`, {
      method: "POST",
      headers: { ...formDataHeaders, ...corsHeaders },
      body: new URLSearchParams(credentials),
      mode: "cors",
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      let responseNew
        switch (response.status) {
          case 400:
            responseNew="Bad Request"
            break;
          case 404:
            responseNew="Not Found"
            break;
          case 422:
            responseNew="Unprocessable Entity"
            break;
        }
      throw new Error(responseNew);
    });
  },
  getPwdRecover:(
      email: string,
      adrWeb: string,
  ): Promise<any> => {
    return fetch(`${apiUrl}/get_lost_password`, {
      method: "POST",
      body: JSON.stringify({email: email, url: adrWeb}), //, }),
      headers: { ...jsonHeaders, ...corsHeaders },
    }).then((response) => {
      console.log(response); // aa@po.fr
      console.log("response", response);
      if (response.ok) {
        return response.json();
      }
      console.log("error")
      throw new Error(response.status.toString());
    })
  },

  checkUid: (uid: string): Promise<any> => {
    const adr = `${apiUrl}/check_uuid`;
    return fetch(adr, {
      method: "POST",
      body: JSON.stringify({uid: uid}),
      headers: { ...jsonHeaders, ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
       }
        throw new Error(response.statusText);
    });
  }
  ,

  RenewPwd: (user_id: string, token: string, new_pwd: string): Promise<any> => {
    // console.log(`RenewPwd index `);
    const adr = `${apiUrl}/renew_pwd`;
    return fetch(adr, {
      method: "POST",
      body: JSON.stringify({user_id: user_id, token: token, new_pwd: new_pwd}),
      headers: { ...jsonHeaders, ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  },


  /**
   * Request for API registration email to be sent at email
   * @param email
   * @param prenom
   * @param nom
   * @param eafc
   * @param password
   */
  getRegistrationEmail: (
    email: string,
    prenom: string,
    nom: string,
    eafc: string,
    password: string
  ): Promise<any> => {
    // return fetch(`${apiUrl}/registration`, {
    console.log(email+" "+prenom+" "+nom+" "+eafc);
    return fetch(`${apiUrl}/users/create_instructor_wait`, {
      method: "POST",
      body: JSON.stringify({ email: email, prenom: prenom, nom: nom, eafc: eafc, password: password }),
      headers: { ...jsonHeaders, ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.status.toString());
    });
  },
  getTokenFromQuickCreds: (
    credentials: {
      email: string;
      auth_uuid: string;
    },
    sequenceCode: string
  ): Promise<any> => {
    return fetch(`${apiUrl}/token/${sequenceCode}`, {
      method: "POST",
      headers: { ...jsonHeaders, ...corsHeaders },
      body: JSON.stringify(credentials),
      mode: "cors",
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  },
  getRenewedToken: (renewalToken: string): Promise<any> => {
    return fetch(`${apiUrl}/token/renew?refresh=${renewalToken}`, {
      method: "GET",
      headers: { ...corsHeaders },
      mode: "cors",
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  },
};

export default repository;
