import { apiUrl } from "@/env";
import {authHeader, corsHeaders, jsonHeaders} from "@/repository/deps";
import { BaseRepository } from "@/repository/base";
import { IUser } from "@/schemas/IUser";
import { Roles } from "@/schemas/Enums";
import any = jasmine.any;

class UserRepository extends BaseRepository<IUser> {
  /**
   * Retrieves sessions attributed to user identified by userId
   * @param token
   * @param userId
   */
  getUserSessions(token: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/me/sessions`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getUserSequencesSessions(token: string, sequenceId: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/me/sessions/${sequenceId}`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getUserSequences(token: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/me/sequences`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  /**
   * Retrieve current user information
   * @param token
   */
  getCurrentUser(token: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/me`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  fetchPowered(token: string): Promise<any> {
    return fetch(
      `${apiUrl}/${this.basePath}?roles=${Roles.Instructor}_${Roles.Admin}_${Roles.Researcher}`,
      {
        method: "GET",
        headers: { ...authHeader(token), ...corsHeaders },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  fetchAdmins(token: string): Promise<any> {
    return fetch(
      `${apiUrl}/${this.basePath}?roles=${Roles.Admin}`,
      {
        method: "GET",
        headers: { ...authHeader(token), ...corsHeaders },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  fetchInstructors(token: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}?roles=${Roles.Instructor}`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  fetchWaitingValidation(token: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/waiting?roles=${Roles.WaitingInstructor}`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  createNUsers(token: string, sequence_code: string, qte: number): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/create_user/${sequence_code}/${qte}`, {
      method: "POST",
      headers: {...authHeader(token), ...corsHeaders},
    }).then((response: Response) => {
      if (response.ok) {

        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  deleteUserSequence(token: string, sequence_code: string, users:[]) {
    return fetch(`${apiUrl}/${this.basePath}/delete_user_sequence/${sequence_code}`, {
      method: "DELETE",
      headers: { ...jsonHeaders, ...authHeader(token), ...corsHeaders },
      body: JSON.stringify(users),
    }).then((response) => {
      console.log("response", response)
      if (response.ok) {
        return true;
      }
      throw new Error(response.statusText);
    })
  }

  deleteWaitingUser(token: string, user:any) {
    return fetch(`${apiUrl}/${this.basePath}/deleteWaitingUser`, {
      method: "DELETE",
      headers: { ...jsonHeaders, ...authHeader(token), ...corsHeaders },
      body: JSON.stringify(user),
    }).then((response) => {
      console.log("response", response)
      if (response.ok) {
        return true;
      }
      throw new Error(response.statusText);
    })
  }

  validateWaitingUser(token: string, users:[]) {
    return fetch(`${apiUrl}/${this.basePath}/validateWaitingUser`, {
      method: "PATCH",
      headers: { ...jsonHeaders, ...authHeader(token), ...corsHeaders },
      body: JSON.stringify(users),
    }).then((response) => {
      console.log("response", response)
      if (response.ok) {
        return true;
      }
      throw new Error(response.statusText);
    })
  }

  ChangePwd(user_id: string, new_pwd: string, old_pwd: string) {
  const adr = `${apiUrl}/${this.basePath}/change_pwd`;
  return fetch(adr, {
    method: "POST",
    body: JSON.stringify({user_id: user_id, new_pwd: new_pwd, old_pwd: old_pwd}),
    headers: { ...jsonHeaders, ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    })
  }

}
const userRepository = new UserRepository("users");

export default userRepository;
